import { RequestParam, RoleResource, UserResource } from "types";
import {
  PaysResource,
  SituationGeographiqueResource,
  VilleResource,
  ZoneResource,
  FerieResource,
} from "types/centre.type";
import { ClientItem, ClientResource } from "types/client.type";
import {
  ClientPrestationResource,
  TransportFactureItemResource,
  PrestationResource,
  TransportFactureResource,
} from "types/facturation.types";
import { DesserteResource, TypeVehiculeResource } from "types/transport.types";
import { requestParams } from "utils/helpers";
import { CrudService } from "./crudService";
import { requestGet, requestPost, requestPut } from "./request";

export * from "./personnelService";
export * from "./referentielService";
export * from "./centreService";
export * from "./centreRegionalService";
export * from "./siteService";
export * from "./secteurActiviteService";
export * from "./vehiculeService";
export * from "./tourneeService";
export * from "./serviceService";
export * from "./fonctionService";
export * from "./authService";
export * from "./exportService";
export * from "./deviseService";

// Clients
const clients = new CrudService<ClientItem, ClientResource>("clients");
export const clientService = {
  ...clients,
  addPrestation: (clientId: string, body: any) => {
    return requestPost<{ data: PrestationResource[] }>(
      `${clients.getBase()}/${clientId}/prestations`,
      body
    ).then(({ data }) => data);
  },
  findPrestations: (clientId: string, params?: RequestParam) => {
    return requestGet<{ data: ClientPrestationResource[] }>(
      `${clients.getBase()}/${clientId}/prestations${requestParams(params)}`
    ).then(({ data }) => data);
  },
  dessertesAfacturer: (clientId: string, params: RequestParam) => {
    return requestGet<{ data: TransportFactureItemResource[] }>(
      `${clients.getBase()}/${clientId}/dessertes-a-facturer${requestParams(
        params
      )}`
    ).then(({ data }) => data);
  },
};

// client prestation
const clientPrestations = new CrudService<ClientItem, ClientResource>(
  "clientprestations"
);
export const clientPrestationService = {
  ...clientPrestations,
};

// prestations
const prestations = new CrudService<PrestationResource, PrestationResource>(
  "prestations"
);
export const prestationService = {
  ...prestations,
};

// Dessertes
const dessertes = new CrudService<DesserteResource, DesserteResource>(
  "dessertes"
);
export const desserteService = {
  ...dessertes,
  generate: (body: any) => {
    return requestPost(`${dessertes.getBase()}/generate-dailydesserte`, body);
  },
  transit: (desserteId: string, body: any) => {
    return requestPut<{ data: DesserteResource }>(
      `${dessertes.getBase()}/${desserteId}/transit`,
      body
    );
  },
  annuler: (desserteId: string) => {
    return requestPut<{ data: DesserteResource }>(
      `${dessertes.getBase()}/${desserteId}/annuler`
    );
  },
  aPlanifier: (params?: RequestParam) => {
    return requestGet<{ data: DesserteResource[] }>(
      `${dessertes.getBase()}/a-planifier${requestParams(params)}`
    );
  },
};

// Pays

const pays = new CrudService<PaysResource, PaysResource>("pays");

export const paysService = {
  ...pays,
};

// Villes
const villes = new CrudService<VilleResource, VilleResource>("villes");
export const villeService = {
  ...villes,
};

// SituationGeographique
const situationGeographiques = new CrudService<
  SituationGeographiqueResource,
  SituationGeographiqueResource
>("situationgeographiques");
export const situationGeographiqueService = {
  ...situationGeographiques,
};

// Zone
const zones = new CrudService<ZoneResource, ZoneResource>("zones");
export const zoneService = {
  ...zones,
};

// Type vehicule
const typeVehicule = new CrudService<
  TypeVehiculeResource,
  TypeVehiculeResource
>("typeVehicules");

export const typeVehiculeService = {
  ...typeVehicule,
};

// users
const users = new CrudService<UserResource, UserResource>("users");

export const userService = {
  ...users,
  createUserFromPersonnel: (personnelId: string, body: any) => {
    return requestPost(`${users.getBase()}/personnels/${personnelId}`, body);
  },
};

// roles
const roles = new CrudService<RoleResource, RoleResource>("roles");

export const roleService = {
  ...roles,
};

// transportfactures
const transportfactures = new CrudService<any, any>("transportfactures");
export const transportfactureService = {
  ...transportfactures,
  generer: (body: any) => {
    return requestPost<{ data: TransportFactureResource[] }>(
      `${transportfactures.getBase()}/generer-factures`,
      body
    );
  },
  annuler: (tranportFactureId: string) => {
    return requestPut<{ data: TransportFactureResource }>(
      `${transportfactures.getBase()}/${tranportFactureId}/annuler`
    );
  },
};

//Jours feries
const feriesBase = "feries";
const feries = new CrudService<FerieResource, FerieResource>(feriesBase);
export const ferieService = {
  ...feries,
  duplicate: (body: any) => {
    return requestPost<{ data: FerieResource }>(
      `${feriesBase}/duplicate`,
      body
    );
  },
};
