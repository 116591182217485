import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import { GlobalLoader, Notification } from "components";
import Locales from "components/Locales";
import { Navigation } from "navigation";
import { ThemeCustomization } from "./themes";

import * as yup from "yup";

import "simplebar/src/simplebar.css";

yup.setLocale({
  mixed: {
    required: "Ce champs est obligatoire",
  },
  string: {
    email: "Doit être un email valide",
  },
});

const App = () => {
  return (
    <ThemeCustomization>
      <Locales>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={frLocale}
        >
          <Navigation />
          <Notification />
          <GlobalLoader />
        </LocalizationProvider>
      </Locales>
    </ThemeCustomization>
  );
};

export default App;
