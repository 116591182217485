import {
  AccessTimeSharp,
  Adjust,
  AlternateEmail,
  Apartment,
  DepartureBoard,
  Event,
  Flag,
  Groups,
  Info,
  ManageAccounts,
  NextPlan,
  Paid,
  PeopleAlt,
  PinDrop,
  Receipt,
  Rule,
  Work,
} from "@mui/icons-material";

export const ViewIcons = {
  infoGenerale: <Info color="primary" />,
  depart: <DepartureBoard color="primary" />,
  arrivee: <Flag color="primary" />,
  facture: <Receipt color="primary" />,
  contact: <AlternateEmail color="primary" />,
  situationGeographique: <PinDrop color="primary" />,
  contrat: <Rule color="primary" />,
  prestation: <Work color="primary" />,
  frequence: <AccessTimeSharp color="primary" />,
  client: <Apartment color="primary" />,
  equipe: <Groups color="primary" />,
  desserte: <Adjust color="primary" />,
  event: <Event color="primary" />,
  escale: <NextPlan color="primary" />,
  prestataire: <PeopleAlt color="primary" />,
  jonction: <ManageAccounts color="primary" />,
  paid: <Paid color="primary" />,
};
