import { useEffect, useMemo, useState } from "react";

import {
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

// assets
import { TableSimple } from "components";
import { PageTitle } from "pages/common";
import { ferieService } from "services";

import { FerieResource } from "types/centre.type";
import { useModal, useUser } from "hooks";
import { addOrUpdateElement } from "utils/helpers";
import { EditTwoTone } from "@ant-design/icons";
import { FerieModal } from "./FerieModal";
import { DuplicateModal } from "./DuplicateModal";
import { dateFormater, dateParser } from "utils/date";

const ListFerie = () => {
  const theme = useTheme();
  const [defaultValues, setDefaultValues] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<FerieResource[]>([]);
  const modal = useModal();
  const duplicateModal = useModal();
  const { paysId } = useUser();
  const annee = new Date().getFullYear();

  useEffect(() => {
    setLoading(true);

    ferieService
      .findAll({ paysId, annee, perpage: 25 })
      .then((resultat) => {
        setData(resultat.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paysId]);

  const save = (data: any) => {
    data.annee = dateFormater.getYear(data.date);
    data.date = dateFormater.toBackPattern(data.date, "yyyy-MM-dd");
    data.paysId = paysId;
    if (defaultValues?.id) {
      return ferieService.update(defaultValues.id, {
        ...data,
      });
    }
    return ferieService.create({
      ...data,
    });
  };

  const onEdit = (original: FerieResource) => {
    setDefaultValues({
      id: original.id,
      date: dateParser.toDate(original.date),
      libelle: original.libelle,
    });
    modal.show();
  };

  const onDuplicate = () => {
    duplicateModal.show();
  };

  const saveDuplication = (data: any) => {
    data.fromCountry = paysId;
    data.fromYear = dateFormater.getYear(data.fromYear);
    data.toYear = dateFormater.getYear(data.toYear);
    return ferieService.duplicate({
      ...data,
    });
  };

  const closeModal = () => {
    modal.close();
    setDefaultValues(undefined);
  };

  const onFinished = (element: FerieResource) => {
    const index = data.findIndex(({ id }) => id === element.id);
    setData((d) => addOrUpdateElement(d, element, index));
    closeModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.date || "-"}</Typography>
        ),
      },
      {
        Header: "Libelle",
        accessor: "libelle",
        Cell: ({ row: { original } }: any) => (
          <Typography variant="subtitle1">{original.libelle}</Typography>
        ),
      },
      {
        Header: "Annee",
        accessor: "annee",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.annee || "-"}</Typography>
        ),
      },
      {
        Header: "Pays",
        accessor: "pays",
        Cell: ({ row: { original } }: any) => (
          <Typography>{original.pays.libelle || "-"}</Typography>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",

        Cell: ({ row: { original } }: any) => (
          <Tooltip title="Modifier le jour férié">
            <IconButton
              color="error"
              size="large"
              onClick={() => onEdit(original)}
            >
              <EditTwoTone twoToneColor={theme.palette.error.main} />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageTitle
        title="Jours fériés"
        subtitle="Consulter et gérer les jours fériés"
        sx={{ padding: "1.5rem 0" }}
        right={
          <>
            <Button variant="contained" onClick={modal.show}>
              Créer un jour férié
            </Button>
            <Button
              variant="contained"
              onClick={() => onDuplicate()}
              style={{ marginLeft: "10px" }}
              color="inherit"
            >
              Dupliquer les jours fériés
            </Button>
          </>
        }
      />

      <TableSimple
        columns={columns}
        data={data}
        withPagination
        loading={loading}
      />
      {modal.open && (
        <FerieModal
          onBack={closeModal}
          onFinished={onFinished}
          onSave={save}
          defaultValues={defaultValues}
        />
      )}
      {duplicateModal.open && (
        <DuplicateModal
          onBack={duplicateModal.close}
          onFinished={() => {
            duplicateModal.close();
          }}
          defaultValues={{
            fromYear: new Date(),
            toYear: new Date((new Date().getFullYear()) + 1, 0, 1),
          }}
          onSave={saveDuplication}
        />
      )}
    </>
  );
};

export default ListFerie;
